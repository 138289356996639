import { memo, useLayoutEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { FormattedMessage } from "react-intl";
import { messagePropType } from "app/utils/propTypes";
import "./LineClamp.scss";
import FloatingButton from "app/pages/.shared/Popover/FloatingButton";

const LineClamp = ({ enableShowMore, lineClampFullText, text, lineCount }) => {
	const [isLineClamped, updateLineClamped] = useState(false);
	const ref = useRef();

	useLayoutEffect(() => {
		if (ref.current) {
			// @see https://stackoverflow.com/questions/52232026/determine-if-ellipsis-is-being-displayed-when-using-webkit-line-clamp-for-multi/52650163
			updateLineClamped(ref.current.offsetHeight < ref.current.scrollHeight === true);
		}
	}, [ref, text]);

	const lineClampClass = classNames("line-clamp", {
		"line-clamp--mutiline": lineCount > 0,
	});

	return (
		<>
			<span
				className={lineClampClass}
				ref={ref}
				style={{
					WebkitLineClamp: lineCount,
				}}
			>
				{text}
			</span>
			{enableShowMore && isLineClamped && (
				<FloatingButton
					popoverClassName={`line-clamp__popover ignore-react-onclickoutside`}
					position="top"
					floatingContent={lineClampFullText}
					referenceButtonClassName="line-clamp__view-more"
					referenceComponent={
						<>
							…
							<a data-testid="read-more-button">
								<FormattedMessage id="general.read.more" />
							</a>
						</>
					}
					displayCrossIcon
					dismissOnFloatingClick
					floatingOffsetMobile={[-13, 0]}
				/>
			)}
		</>
	);
};

LineClamp.propTypes = {
	lineCount: PropTypes.number,
	text: messagePropType,
	enableShowMore: PropTypes.bool,
	lineClampFullText: PropTypes.element,
};

export default memo(LineClamp);
