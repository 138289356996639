function IconNegoCheck(props) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="18"
			height="18"
			viewBox="0 0 18 18"
			fill="none"
			{...props}
		>
			<rect width="18" height="18" fill="white" />
			<path
				d="M3 10.1429L6.6 14L15 5"
				stroke="currentColor"
				strokeWidth="2"
				strokeLinecap="round"
			/>
		</svg>
	);
}

export default IconNegoCheck;
